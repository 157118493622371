/**
 * スクロールをロックするclassを付与
 */
function scrollLock () {
  document.body.classList.add('l-body-hidden')
}

/**
 * スクロールをロックするclassを削除
 */
function scrollUnlock () {
  document.body.classList.remove('l-body-hidden')
}

/**
 * スクロールをロックするclassをtoggle
 */
function scrollStateInvert () {
  document.body.classList.toggle('l-body-hidden')
}

export { scrollLock, scrollUnlock, scrollStateInvert }

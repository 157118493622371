import { Controller } from '@hotwired/stimulus'
import { scrollStateInvert, scrollUnlock } from '../scroll-control'

/**
 * ハンバーガーメニューの開閉を制御する Controller
 */
export class HeaderController extends Controller {
  /**
   * メニューをクリックした時のイベントを設定する
   */
  connect () {
    const anchorLink = this.navlistTarget.querySelectorAll('.l-header__nav-anchor-link')
    this.navListClickRedy(anchorLink)
  }

  /**
   * ハンバーガーメニューの開閉を制御するclassを付け替える
   */
  menuToggle () {
    this.humbergerTarget.classList.toggle('l-header__humberger--active')
    this.navTarget.classList.toggle('l-header__nav--active')
    this.navTarget.classList.toggle('l-header__nav--inactive')
    scrollStateInvert()
  }

  /**
   * メニューを選択した時にclassを付け替えるためのイベントを準備
   *
   * @param {Array} anchorLink
   */
  navListClickRedy (anchorLink) {
    anchorLink.forEach((element) => {
      element.addEventListener('click', () => {
        this.humbergerTarget.classList.toggle('l-header__humberger--active')
        this.navTarget.classList.toggle('l-header__nav--active')
        this.navTarget.classList.toggle('l-header__nav--inactive')
        scrollUnlock()
      })
    })
  }
}

HeaderController.targets = ['header', 'humberger', 'nav', 'navlist']
